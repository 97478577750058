import React, { useEffect, useRef } from "react";
import { scrollIntro, scrollTitle } from "./animate";
const Intro = () => {
  //  let textRef = useRef(null);
  let titleRef = useRef(null);
  useEffect(() => {
    scrollTitle(titleRef, "intro_title");
    //   scrollIntro(textRef, "intro_text");
  }, []);
  return (
    <div className=" website_content info_content " >
      <div className="flex-column d-center text-center intro mb-5 pb-5 px-lg-5 px-1">
        <h1
          className="h2 text-dark mb-2  inter  ml-n6  home_health_care_text"
          ref={(el) => (titleRef = el)}
          id="intro_title"
          style={{ marginLeft: "-7rem", marginTop: "rem" }}
        >
          Total HealthCare
        </h1>

        <p
          className="text-light   " id="intro_text"
          //   ref={(el) => (textRef = el)}
          style={{

            textAlign: "left",
            width: "91%",
            fontSize: "1rem"

          }}
        >


Total HealthCare is an all-in-one SaaS platform revolutionizing healthcare management for providers and patients. Designed for hospitals, clinics, labs, pharmacies, and independent doctors, it integrates EHR, LIS, RIS, Billing, Telehealth, and more. Patients benefit from seamless digital services, including appointments, e-consultations, records and test results, while providers streamline operations with tools for referrals, pre-authorizations, and analytics. With mobile access and real-time collaboration, Total HealthCare empowers efficient, patient-centric care delivery anytime, anywhere.
        </p>
      </div>
    </div>
  );
};
export default Intro;
