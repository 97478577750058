import React from "react";
import { Circles, RoundedSquares, Squares } from "../../components/icons";
import { DetailShapesPoint, IndependentPoint, Point, ShapesPoint, SolutionPoint } from "../../components/points";
const Services = ({ ref }) => {
  const services = [
    {
      name: "Access to healthcare seekers ",
      subName: "whether remote or in person",
      details:
        "🌐 Access to healthcare seekers, whether remote or in person.",
      subDetails: "📞💬 Access to patients who need your care via voice/video calls or text.",
      text: "📂💻 Efficiently manage patients' records digitally.",
      icon: Circles,
    },
    {
      name: "Practice conveniently from ",
      subName: "anywhere",
      details:
        "360 model bringing together all services; thereby providing a complete health care ecosystem.",
      icon: RoundedSquares,
    },
    {
      name: "Access to healthcare facilities",
      subName: "effortlessly",
      details: `Connect to real hospital and rent facilities from hospitals when in need of temporary work facilities.`,
      icon: Squares,
    },
  ];
  return (
    <div className="website_content mt-2 pt-3 " ref={ref}>
      <div className=" d-align-start ml-n2 mb-5">
        <SolutionPoint
          title="Independent Doctors"
          body="The platform provides independent consultants and doctors with comprehensive tools to efficiently manage remote consultancy services. Its main features include patient signup, seamless records management, and integrated video calling capabilities. Additionally, the platform incorporates self-assessment devices to enhance the remote consultancy experience. This robust set of features empowers independent consultants to effectively deliver healthcare services remotely, ensuring convenient and high-quality care for their patientsTotal HealthCare is an integrated SaaS platform and mobile app designed for independent doctors. It offers a comprehensive consulting management system with tools for calendar management, online payments, secure medical records access, e-consultations, video consultations, and real-time test result sharing. Empowering doctors to deliver seamless, patient-centered care, Total HealthCare also enables external practice affiliation, connecting providers with patients and enhancing the delivery of quality healthcare services."
          className="col-12 "
        />
      </div>
      <div className="flex-wrap d-justify-between d-align-start  mb-5 padding_nulify">
        {services.map((item, i) => {
          let Icon = item.icon;
          return (
            <DetailShapesPoint
              key={i + item.name}
              className={`col-12 col-sm-6 col-md-4 ${i === services.length - 1
                ? "border-after-none"
                : "border-after-md-grey"
                } ${i === 0 && "border-after-sm-grey"}`}
              title={item.name}
              subtitle={item.subName}
              body={item.details}
              body2={item.subDetails}
              body3={item.text}

              icon={
                <Icon fill="#fff" width="18px" height="18px" className="mb-2" />
              }
            />
          );
        })}
      </div>
    </div>
  );
};
export default Services;
