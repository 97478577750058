
import React from "react";
import { Circles, RoundedSquares, Squares } from "../../components/icons";
import { DetailShapesPoint, SolutionPoint } from "../../components/points";
const Services = () => {
  const services = [

    {
      name: "Manange customers ",
      text: "and services listing",
      details:
        "Access to patients who need your test and   ",
      details2: "diagnosis services. Manage appointments",
      details3: "and requests",
      icon: Circles,
    },
    {
      name: "Set and manage fees",
      text: "and records for consultants",
      details:
        "Patients registration, medical records system ",
      details2: " and electronic records request and ",
      details3: "issuance..",
      icon: RoundedSquares,
    },
    {
      name: "Easy referral ",
      text: "system",
      details: `Get referral from consultants, hospitals, `,
      details2: "Insurance provider and patients alike.",

      icon: Squares,
    },
  ];
  return (
    <div className="website_content mt-2 pt-3">
      <div className="w-100 ml-n2 mb-4">
        <SolutionPoint
          title="Diagnostic Centres & Laboratories"
          body=" Total HealthCare is an all-in-one SaaS platform with mobile app, designed for diagnostic centers and labs. It integrates Practice Management, EHR, LIS, RIS, Billing, Telehealth, and more, streamlining operations and enhancing patient care. Key features include Turn-Around-Time Tracker, device integrations, image storage, scheduling, and automated reminders. The platform enables seamless digital services like online payments, appointment booking, e-consultations, and real-time test result sharing, improving patient engagement, reducing no-shows, and boosting revenue while connecting providers to independent doctors and community pharmacies."
          className="col-12 "
        />
      </div>
      <div className="flex-wrap d-justify-between d-align-start mb-5 ml-n4">
        {services.map((item, i) => {
          let Icon = item.icon;
          return (
            <DetailShapesPoint
              key={i + item.name}
              className={`col-12 col-sm-6 col-md-4  ${i === services.length - 1
                ? "border-after-none"
                : "border-after-md-grey"
                } ${i === 0 && "border-after-sm-grey"}`}
              title={item.name}
              body={item.details}
              subtitle={item.text}
              body2={item.details2}
              body3={item.details3}
              body4={item.details4}
              icon={
                <Icon fill="#fff" width="18px" height="18px" className="mb-2" />
              }
            />
          );
        })}
      </div>
    </div>
  );
};
export default Services;
