import React from "react";
import { Circles, RoundedSquares, Squares } from "../../components/icons";
import { DetailShapesPoint, SolutionPoint } from "../../components/points";
const Services = () => {
  const services = [

    {
      name: "Manage patients",
      text: "and prescription needs",
      details:
        "Receive prescription request from customers  ",
      details2: "customers via the digital platform and provide delivery",
      details3: "or customer pickup options.",
      icon: Circles,
    },
    {
      name: "product listing",
      text: "and selling",
      details:
        "Customers get visibitlity of all your stocked",
      details2: " products.",
      icon: RoundedSquares,
    },
    {
      name: "Improve customer ",
      text: "experience and loyalty",
      details: `Connect with your customers to better `,
      details2: "better understand their needs.",

      icon: Squares,
    },
  ];
  return (
    <div className="website_content mt-2  pt-3">
      <div className="w-100 ml-n2 mb-4">
        <SolutionPoint
          title="Pharmacies"
          body=" Our platform offers pharmacies a comprehensive solution with features like POS, billing, inventory management, and sales/delivery functionalities. Streamline operations with one inventory system for both store and online sales, e-prescribing, medication dispensing, and drug interaction alerts. Track sales, revenue, and inventory with detailed analytics, while ensuring regulatory compliance (GDPR, HIPAA). Enjoy mobile app integration, automated refill reminders, supplier management, and multi-location support, all designed to enhance efficiency and customer experience."
          className="col-12 "
        />
      </div>
      <div className="flex-wrap d-justify-between d-align-start mb-5 ml-n4">
        {services.map((item, i) => {
          let Icon = item.icon;
          return (
            <DetailShapesPoint
              key={i + item.name}
              className={`col-12 col-sm-6 col-md-4  ${i === services.length - 1
                ? "border-after-none"
                : "border-after-md-grey"
                } ${i === 0 && "border-after-sm-grey"}`}
              title={item.name}
              body={item.details}
              subtitle={item.text}
              body2={item.details2}
              body3={item.details3}
              body4={item.details4}
              icon={
                <Icon fill="#fff" width="18px" height="18px" className="mb-2" />
              }
            />
          );
        })}
      </div>
    </div>
  );
};
export default Services;
