import React from "react";
import { Circles, RoundedSquares, Squares } from "../../components/icons";
import { DetailShapesPoint, Point, ShapesPoint, SolutionPoint } from "../../components/points";
const Services = () => {
  const services = [
    {
      name: "Manage patients ",
      text: "records and doctors’ profiles",
      details:
        "Extend remote services to patients who need ",
      details2: "access to you via voice/video calls or text.",
      details3: " Manage patients records digitally.",
      icon: Circles,
    },
    {
      name: "Manage consultancy ",
      text: "and appointment schedules",
      details:
        "Manage doctor scheldules via the web and ",
      details2: " mobile application. Manage patient ",
      details3: "appointments, booking requests and",
      details4: "respond to online consultations",

      icon: RoundedSquares,
    },
    {
      name: "Setup patient",
      text: "and payment charges",
      details: `Set and manage general practise  `,
      details2: "consultancy and specialist fees for remote",
      details3: "consultancy including equipment rental and",
      details4: "emergency services.",
      icon: Squares,
    },
  ];
  return (
    <div className="website_content mt-2 pt-3">

      <div className=" d-align-start ml-n3 mb-5">
        <SolutionPoint
          title="Hospitals"
          body="Total HealthCare is an all-in-one SaaS cloud platform with mobile app, designed for hospitals and clinics. It integrates Practice & Operations Management, EHR, LIS, RIS, Pharmacy, Billing, Telehealth, and more. By streamlining operations and enhancing collaboration, it empowers healthcare providers to deliver seamless patient care. Key features like online payments, appointment booking, e-consultations, and medical records access improve patient engagement and reduce no-shows, ultimately boosting revenue and connecting providers to independent doctors, pharmacies, and labs for enhanced community care."
          className="col-12 "
        />
      </div>
      <div className="flex-wrap d-justify-between d-align-start mb-5 ml-n4">
        {services.map((item, i) => {
          let Icon = item.icon;
          return (
            <DetailShapesPoint
              key={i + item.name}
              className={`col-12 col-sm-6 col-md-4  ${i === services.length - 1
                ? "border-after-none"
                : "border-after-md-grey"
                } ${i === 0 && "border-after-sm-grey"}`}
              title={item.name}
              body={item.details}
              subtitle={item.text}
              body2={item.details2}
              body3={item.details3}
              body4={item.details4}
              icon={
                <Icon fill="#fff" width="18px" height="18px" className="mb-2" />
              }
            />
          );
        })}
      </div>
    </div>
  );
};
export default Services;
