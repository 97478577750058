import React, { useRef, useEffect } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import imag_logo from "../../assets/images/web_logo.png"

gsap.registerPlugin(ScrollTrigger);
const Services = () => {
  let servicesRef = useRef([]);
  servicesRef.current = [];
  useEffect(() => {
    servicesRef.current.forEach((el, i) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0, opacity: 0 },
        {
          duration: 0.5,
          autoAlpha: 1,
          opacity: 1,
          ease: "none",
          translateY: -30,
          scrollTrigger: {
            trigger: el,
            //     start: "top center+=100",
            toggleActions: "play none none none",
          },
        }
      );
    });
  }, []);
  const revealLists = (elem) => {
    if (elem && !servicesRef.current.includes(elem)) {
      servicesRef.current.push(elem);
    }
  };

  return (
    <div className="ml-n1 website_content mt-5  footer_section" 
    style={{
      height: "23rem"
    }}
    >
      <div className="flex-wrap ml-1 d-justify-between  flex-row d-align-center   "
      
      
      >
       
<div  style={{
  marginRight: "-4rem"
}}>

<h3 className="text-dark mb-1  inter text-x text-b" style={{  fontSize: "22px", }}>
              Welcome to  <br />
              Total HealthCare!
            </h3>
            <img src={imag_logo} style={{ width: "4rem", height: "4rem", marginBottom: "1rem" }} />
</div>
<div>

</div>
<div className="text-light aileron text-n "  style={{width: "80%", marginTop: "1rem" }}>
 <p className="text-light aileron text-n"  style={{ fontSize: "1rem", marginLeft: "3rem", marginRight: "0.8rem" }}> At Total HealthCare, we understand the complexities of managing healthcare services and navigating the myriad of healthcare options available. Our mission is to ensure seamless, accessible, and high-quality healthcare for everyone. Recognizing the challenges of delivering telemedicine services across diverse regions and platforms, we focus on providing smooth, uninterrupted interactions between healthcare providers and patients.</p>
 <p className="text-light text-left aileron text-n"  style={{ fontSize: "1rem", marginLeft: "3rem", marginRight: "0.5rem" }}>Leveraging cutting-edge technology, we simplify remote connections while prioritizing the safety and security of patient data. Our platform empowers healthcare organizations of all sizes to thrive, offering powerful tools that foster growth and operational efficiency. With Total HealthCare, you can confidently access a comprehensive range of telemedicine solutions, all customized to meet the unique needs of your organization, regardless of geographic location.</p>
 <p className="text-light text-left aileron text-n"  style={{ fontSize: "1rem",  marginLeft: "3rem" , marginRight: "0.5rem" }}>Together, we’ll shape the future of healthcare with security, convenience, and innovation at its core.</p>
</div>
      </div>
    </div>
  )

};
export default Services;
