import React, { useRef, useEffect } from "react";
import {
  Ambulance,
  Calender,
  Medic,
  Tele,
  Video,
} from "../../components/icons";
import { IconPoint } from "../../components/points";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
const Services = () => {
  let servicesRef = useRef([]);
  servicesRef.current = [];
  useEffect(() => {
    servicesRef.current.forEach((el, i) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0, opacity: 0 },
        {
          duration: 0.5,
          autoAlpha: 1,
          opacity: 1,
          ease: "none",
          translateY: -30,
          scrollTrigger: {
            trigger: el,
            //     start: "top center+=100",
            toggleActions: "play none none none",
          },
        }
      );
    });
  }, []);
  const revealLists = (elem) => {
    if (elem && !servicesRef.current.includes(elem)) {
      servicesRef.current.push(elem);
    }
  };

  const services = [
    {
      name: "Medical records management ",
      details:
        "Effortlessly manage patient records, prescriptions, and diagnostic results with our integrated digital system. Simplify operations, eliminate paperwork, and embrace seamless healthcare management—all in one powerful platform.",
      icon: Medic,
    },
    {
      name: "Appointments and referrals",
      details:
        "Streamline appointments with automated reminders via email, SMS, or app notifications. Our seamless referral system ensures smooth record exchange between providers, letting you focus on health while we handle the details.",
      icon: Calender,
    },
    {
      name: "Right health care access",
      details:
        "Empowering users with clear options, including proximity and availability insights, enabling informed decisions for accessible, convenient, and personalized healthcare solutions tailored to individual needs, anytime, anywhere.",
      icon: Medic,
    },
    {
      name: "Telemedicine",
      details:
        "Embrace the power of Telemedicine! Connect with healthcare professionals and integrate diagnostic devices effortlessly from home. Experience swift, seamless, and effective health assessments—quality care is just a click away!",
      icon: Tele,
    },
    {
      name: "Video Consultation",
      details: "Instant Care, Anytime! Connect with certified specialists via video consultation in minutes. Skip hospital queues and delays—access professional medical advice directly from your doctor or consultant on your mobile device. ",
      icon: Video,
    },
    {
      name: "Appointments & Emergencies",
      details:
        "Easily book and manage appointments through our mobile app, on your schedule. Plus, request an ambulance—either through your health plan or on-demand—from available service providers, all with a few taps.",
      icon: Tele,
    },
    
  ];
  return (
    <div className="website_content">
      <div className="flex-wrap d-justify-between d-align-start px-lg-5 px-1 mb-n1 padding_nulify">
        {services.map((item, i) => {
          let Icon = item.icon;
          return (
            <div
              ref={revealLists}
              key={i + item.name}
              className="col-12 col-md-6 col-xxl-4"
            >
              <IconPoint
                className=""
                iconClass="bg-primary"
                title={item.name}
                body={item.details}
                icon={<Icon fill="#fff" width="18px" height="18px" />}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Services;
